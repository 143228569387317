<template>
  <v-main>
    <v-dialog v-model="dialogReset" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Reset Your Password ?</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model.number="resetEmail"
                  type="email"
                  label="Email"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeReset">Cancel</v-btn>
          <v-btn color="primary" text @click="sendReset" :loading="loadingReset"
            >Reset</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>SAMSYS Admin</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  label="Email"
                  name="email"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="email"
                />
                <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="password"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="loading" @click="login"
                >Login</v-btn
              >
            </v-card-actions>
            <v-card-text class="text-center">
              <a @click="forgotPassword">Reset password</a>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex';
import loginService from '../../services/login.services';

export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      password: '',
      email: '',
      dialogReset: false,
      resetEmail: '',
      loadingReset: false,
    };
  },
  methods: {
    ...mapActions(['setAdminToken', 'setSnackbarInfos', 'askResetPassword']),
    forgotPassword() {
      this.resetEmail = this.email;
      this.dialogReset = true;
    },
    closeReset() {
      this.resetEmail = '';
      this.dialogReset = false;
    },
    async sendReset() {
      this.loadingReset = true;
      try {
        await this.askResetPassword(this.resetEmail);
        this.closeReset();
      } catch (err) {
        console.error(err);
      }
      this.loadingReset = false;
    },
    login() {
      const payload = {
        password: this.password.trim(),
        email: this.email.trim().toLowerCase(),
      };

      this.loading = true;

      loginService
        .login(payload)
        .then(({ data }) => {
          this.setAdminToken(data.jwt);
          this.$router.push('/');
        })
        .catch(({ response }) => {
          this.setSnackbarInfos({
            message: response.data.message || 'Erreur inconnue',
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
